body {
  margin: 0;
}

header {
  transform: none !important;
  visibility: inherit !important;
}

.ps__rail-y {
  display: none;
}

#main-content {
  padding: 0px !important;
  margin: 0px;
}

#main-content .show-page,
#main-content .edit-page,
#main-content .list-page,
#main-content .create-page {
  padding: 24px;
}

.MuiTab-root {
  min-width: 145px !important;
}

.RaSidebar-fixed-41 {
  z-index: 1000;
}

.MuiDrawer-root {
  z-index: 5 !important;
}
